@each $color, $value in $grays {
  .link-#{$color} {
    color: $value !important;

    &:hover,
    &:focus {
      color: darken($value, 15%) !important;
    }
  }
}

