.nav-tabs {
  border-bottom: 2px solid #F9FAFD;

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    border: $white;
    color:$gray-800;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    @include border-top-radius($nav-tabs-border-radius);

    &:hover,
    &:focus {
      border-color: $white;
    }

    &.disabled {
      color: $black;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $primary;
    background-color: $white;
    border-bottom: 2px solid $primary;
  }

}