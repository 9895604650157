
/* -------------------------------------------------------------------------- */
/*                               Settings Panel                               */
/* -------------------------------------------------------------------------- */

.btn-group-navbar-style{
  input[name="navbarStyle"]{
    + .btn-navbar-style{
      box-shadow: none;
      padding: 0;
      margin-bottom: 1rem;
      text-align: left;
      .img-prototype{
        border: 3px solid $gray-200;
        border-radius: $border-radius-lg;
        transition: border 0.2s ease;
        margin-bottom: 0.5rem;
        background-color: $gray-100;
      }
      .label-text{
        position: relative;
        padding-left: 1.5rem;
        &:after{
          position: absolute;
          content: '';
          left: 0;
          width: 1rem;
          height: 1rem;
          border: 1px solid $gray-500;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          transition: border 0.2s ease;
        }
      }
    }
    &:checked:not([disabled]) + .btn-navbar-style{
      .img-prototype{
        border-color: $success;
      }
      .label-text::after{
        border: 5px solid $success;
      }
    }
    &:disabled{
      + .btn-navbar-style{
        opacity: 0.5;
      }
    }
  }
}